
// Returns a promise object
function getPromise(data) {
    return $.ajax(data);
}

function getCurrentDate(twelve = true) {
    let dateOptions = {
        hour12 : twelve,
        weekday : "long",
        month : "long",
        day : "2-digit",
        year : "numeric"
    };
    let currentDate = new Date().toLocaleDateString(undefined, dateOptions);
    return currentDate;
}

// Returns the current time as a string like "5:30 pm"
function getCurrentTime() {
    var currentTime = new Date().toLocaleTimeString(navigator.language, { hour : '2-digit', minute: '2-digit'} );
    return currentTime.toLowerCase();
}

function checkIfUpdate(ts, minDiff){
    return (!ts || (Date.now() - ts)/1000 > (minDiff * 60))
}

function openUrl(url)
{
    window.open(url, '_blank');
}

const searchParams = new URL(window.location.href).searchParams;
searchParams.forEach((value, param) => setSetting(param, value));

function getSetting(name) {
   return localStorage.getItem(name);
}

function setSetting(name, value) {
    localStorage.setItem(name, value);
}

function sendMessage(msgName, msgObj){
    return sendMessageToExt({task: "sendMessage", msg: msgName, obj: msgObj});
}

function sendMessageToExt(msgToPost){
    return new Promise(function (resolve, reject){
        var receiveMessage = function(e){
            if (e.data  && e.data.msg == "msgResponse"){
                window.removeEventListener("message", receiveMessage, false);
                resolve(e.data.res);
            }
        }
        window.addEventListener("message", receiveMessage, false);
        window.postMessage(msgToPost, "*");
        setTimeout(function() {
            reject('message timed out');
        }, 500);
    });
}


function getTopDomain() {
    if (typeof DOMAIN !== "undefined") return DOMAIN;
    const hostname = window.location.hostname;
    let parts = hostname.split(/\./);
    return parts.length == 1 ? hostname : parts.slice(-2).join(".")
}

const TOP_DOMAIN = getTopDomain();

module.exports = {
    openUrl,
    getPromise,
    getCurrentDate,
    getCurrentTime,
    getSetting,
    setSetting,
    checkIfUpdate,
    TOP_DOMAIN
};