import {TOP_DOMAIN} from './util'

//start of autocomplete
$(document).ready(() => {
    if(window.jQuery && window.jQuery.ui && window.jQuery.ui.autocomplete){
    (function($){
        $("#search-text").autocomplete({
            minLength:2,
            select: function( e, ui ) {
                $("#search-text").parents('form:first').submit();
            },
            source: function(request, response) {
                $.ajax({
                    url: "https://sug." + TOP_DOMAIN + "/sug/?s=" + $("#search-text").val(),
                    dataType: "jsonp",
                    data: {
                        "output": "jsonp",
                        "command": request.term
                    },
                    success: function(data) {
                        var suggestions = [];
                        if(data.gossip){
                            $.each(data.gossip.results, function(i, val) {
                                suggestions.push(val.key);
                            });
                        }
                        else {
                            suggestions = data[1];
                        }

                        response(suggestions);
                    }
                });
            }
        });

        $.ui.autocomplete.prototype._renderItem = function( ul, item) {
            var re = new RegExp(this.term) ;
            var t = item.label.replace(re,"<span style='font-weight:bold;color:#324fe1;'>" +
                this.term +
                "</span>");
            return $( "<li style='margin-top:3px;'></li>" )
                .data( "item.autocomplete", item )
                .append( "<a>" + t + "</a>" )
                .appendTo( ul );
        };
    })(jQuery);
}
})
